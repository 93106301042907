import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFView SetColorPostProcessMapFile:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl SetColorPostProcessMapFile:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotTypes.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotTypeCallout</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameCallout</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationPasteboard.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTAnnotationPasteboard copyAnnotations:withPDFViewCtrl:completion:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotationPasteboard.sourcePageNumber</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationPasteboard copyAnnotations:withPDFViewCtrl:fromPageNumber:completion:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotBarButtonCallout</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotEditTool.aspectRatioGuideEnabled</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotEditTool editSelectedAnnotationFreeText]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotEditTool frameForEditingFreeTextAnnotation]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCalloutCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCalloutCreate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCalloutEditTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCalloutEditTool</div>
<div class="difference"><span class="status added">Added</span> PTCalloutEditTool.calloutStartPoint</div>
<div class="difference"><span class="status added">Added</span> PTCalloutEditTool.calloutKneePoint</div>
<div class="difference"><span class="status added">Added</span> PTCalloutEditTool.calloutEndPoint</div>
<div class="difference"><span class="status added">Added</span> PTCalloutEditTool.contentRect</div>
<div class="difference"><span class="status added">Added</span> PTCalloutEditTool.calloutStartResizeWidget</div>
<div class="difference"><span class="status added">Added</span> PTCalloutEditTool.calloutKneeResizeWidget</div>
<div class="difference"><span class="status added">Added</span> PTCalloutEditTool.selectedResizeWidget</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.changesPageOnTap</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.activityViewController</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewSettingsController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewSettingsController init]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettingsController.pdfViewCtrl</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewSettingsController initWithPDFViewCtrl:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFreeTextAnnotationOptions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFreeTextAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> PTFreeTextAnnotationOptions.defaultFontName</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFreeTextCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFreeTextCreate.textView</div>
<div class="difference"><span class="status added">Added</span> -[PTFreeTextCreate commitAnnotation]</div>
<div class="difference"><span class="status added">Added</span> -[PTFreeTextCreate createFreeText]</div>
<div class="difference"><span class="status added">Added</span> -[PTFreeTextCreate setRectForFreeText:]</div>
<div class="difference"><span class="status added">Added</span> -[PTFreeTextCreate setPropertiesForFreeText:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTReflowViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTReflowViewController.fontOverrideName</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTResizeWidgetView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTResizeHandleLocationNone</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectContainerView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTSelectionRectContainerView useTextViewWithText:withAlignment:atZoom:forFontSize:withDelegate:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTSelectionRectContainerView useTextViewWithText:withAlignment:atZoom:forFontSize:withFontName:withFrame:withDelegate:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTThumbnailsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTThumbnailsViewController.editingSupported</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTool.annotType</div>
<div class="difference"><span class="status added">Added</span> -[PTTool willModifyAnnotation:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTool willRemoveAnnotation:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTool CGRectScreen2PDFRectPage:PageNumber:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolManager.calloutAnnotationOptions</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager willModifyAnnotation:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager willRemoveAnnotation:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerDelegate toolManager:willModifyAnnotation:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerDelegate toolManager:willRemoveAnnotation:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerAnnotationWillModifyNotification</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerAnnotationWillRemoveNotification</div>
<div class="difference"><span class="status added">Added</span> -[PTFreeText setFontWithName:pdfDoc:]</div>
<div class="difference"><span class="status added">Added</span> -[PTFreeText getFontName]</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerViewControllerPresentation</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerViewControllerPresentation prefersNavigationBarHidden]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTToolManager.freeTextAnnotationOptions</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) PTAnnotationOptions *freeTextAnnotationOptions</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nonnull) PTFreeTextAnnotationOptions *freeTextAnnotationOptions</td></tr>
</table>
<br>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}